import {styled} from "@mui/system";

const withDiagonalBackground = (Component) => styled(Component)(({theme}) => ({
  background: `linear-gradient(68.07deg, ${theme.palette.primary.darker} 19.04%, ${theme.palette.primary.lighter} 81.98%)`,
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    bottom: '-140%',
    width: '200%',
    height: '122%',
    transformOrigin: 'bottom left',
    transform: 'rotate(-24deg)',
    backgroundColor: theme.palette.background.default,
  },
  '& > *': {
    zIndex: 10,
  }
}));

export default withDiagonalBackground;
