import React from 'react';
import {Box, Grid, Typography} from "@mui/material";
import {styled} from "@mui/system";
import Navbar from "../navbar/navbar";
import {CallToActionButton} from "../common/Button";
import withDiagonalBackground from "../common/withDiagonalBackground";
import BaseContainer from "../common/BaseContainer";
import {StaticImage} from "gatsby-plugin-image";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import {Link} from "gatsby";

const Wrapper = styled(withDiagonalBackground('div'))(() => ({
  minHeight: '100vh',
}));

const subtitles = [
  <>Wszystko czego potrzebujesz do <strong>bezpiecznej</strong> obsługi
    płatności&nbsp;online&nbsp;(BLIK,&nbsp;paybylink), KYC, AML i
    MFA.</>,
  <>W jednym miejscu, z <strong>wygodnym</strong> REST API i naszym <strong>przyjaznym</strong> wsparciem.</>,
];

const HeroImageWrapper = styled('div')(() => ({
  position: 'absolute', right: 0, zIndex: 0,
}))

const Hero = () => (
  <Wrapper sx={{'&::before': {display: {xs: 'none', lg: 'block'}}}}>
    <HeroImageWrapper sx={{display: {xs: 'none', xl: 'block'}}}>
      <StaticImage src='../../images/hero_image.png' alt='Hero image' placeholder='blurred'/>
    </HeroImageWrapper>
    <BaseContainer>
      <Navbar/>
      <Typography
        variant='h2' component='h1'
        sx={{
          fontSize: {xs: '3rem', sm: '4rem', md: '5rem', lg: '6rem'},
          fontWeight: 'bold',
          mt: 8,
          zIndex: 100,
        }}
        color='white'>
        Płatności z <br/>supermocami
      </Typography>
      <Grid container sx={{my: 4}} spacing={2}>
        {subtitles.map((subtitle, idx) => (
          <Grid item xs={12} key={idx}>
            <Typography variant='subtitle1' sx={{color: 'white', fontWeight: 'light'}}>
              {subtitle}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Box sx={{display: 'flex', justifyContent: {xs: 'center', lg: 'flex-start'}}}>
        <Link to='/register'>
          <CallToActionButton
            endIcon={<ArrowForwardOutlinedIcon/>}
            sx={{mt: 8, mb: {xs: 8, lg: 0}}}
          >
            Zarejestruj się
          </CallToActionButton>
        </Link>
      </Box>
      <Grid container justifyContent='center' sx={{my: 4, color: {xs: 'white', lg: 'black'}}}>
        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', zIndex: 10}}>
          Nasi klienci przyjęli już ponad
        </Grid>
        <Grid item xs={12}
              sx={{display: 'flex', justifyContent: 'center', zIndex: 10, fontSize: '2rem', fontWeight: 'bold'}}>
          11.500.000 zł
        </Grid>
        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', zIndex: 10}}>
          płatności przy użyciu naszej technologii
        </Grid>
      </Grid>
    </BaseContainer>
  </Wrapper>
);

export default Hero;
