import React, {useState} from "react";
import {Box, Grid, IconButton, Menu, MenuItem, Toolbar, Typography, useMediaQuery, useTheme} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {Link as GatsbyLink, navigate} from "gatsby";
import Button from "../common/Button";
import FiberpayLogoImage from "../common/FiberpayLogoImage";

const menuItems = [{
  name: 'Produkty',
  uri: '/#produkty'
}, {
  name: 'Branże',
  uri: '/#branze',
}, {
  name: 'Dla programistów',
  uri: '/#dla-programistow',
}, {
  name: 'Cennik',
  uri: '/#cennik',
}, {
  name: <>O&nbsp;nas</>,
  uri: '/#o-nas',
}];

// noinspection JSValidateTypes
const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClick = (navigateTo) => {
    navigate(navigateTo);
    handleCloseNavMenu()
  }

  const theme = useTheme();
  const loginButtonSize = useMediaQuery(theme.breakpoints.up("sm")) ? 'medium' : 'small';

  // noinspection JSValidateTypes
  return <header>
    <Toolbar disableGutters component='nav' sx={{bgcolor: 'transparent', py: 4}}>
      <Grid container>
        <Grid item xs={2} sx={{display: {xs: 'flex', lg: 'none'}, color: 'white'}}>
          <Box>
            <IconButton
              size="large"
              aria-label="App navigation"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={!!anchorElNav}
              onClose={handleCloseNavMenu}
              sx={{
                display: {xs: 'flex', lg: 'none'},
              }}
            >
              {menuItems.map(({name, uri}) => (
                <MenuItem key={name} onClick={() => handleClick(uri)}>
                  <Typography textAlign="center"
                              sx={{
                                mx: {xs: 2, lg: 4},
                                color: 'primary.main',
                                textDecoration: 'none'
                              }}>{name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Grid>
        <Grid item xs={8} sx={{display: {xs: 'flex', lg: 'none', justifyContent: 'center', alignItems: 'center'}}}>
          <GatsbyLink to='/'>
            <FiberpayLogoImage/>
          </GatsbyLink>
        </Grid>
        <Grid item xs={2} sx={{display: {xs: 'none', lg: 'flex', alignItems: 'center'}}}>
          <GatsbyLink to='/'>
            <FiberpayLogoImage/>
          </GatsbyLink>
        </Grid>

        <Grid item xs={8}
              sx={{px: 4, display: {xs: 'none', lg: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}}>
          {menuItems.map(({name, uri}) => (
            <Button
              key={name}
              onClick={() => handleClick(uri)}
              sx={{
                border: 'none',
                color: 'white',
                textDecoration: 'none',
                background: 'transparent',
                fontWeigth: '400',
                '&:hover': {background: 'rgba(255, 255, 255, 0.05)'}
              }}
            >
              {name}
            </Button>
          ))}
        </Grid>


        <Grid item xs={2} sx={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
          <Button as={GatsbyLink} to='/login' size={loginButtonSize} sx={{
            bgcolor: 'transparent',
            border: '2px solid white',
            color: 'white',
            textDecoration: 'none',
            transition: 'box-shadow 0.25s',
            fontWeight: 400,
            '&:hover': {
              border: '2px solid white',
              bgcolor: 'rgba(255, 255, 255, .2)',
              boxShadow: '-4px -4px 4px rgba(255, 255, 255, 0.05), 4px 4px 8px rgba(0, 0, 0, 0.25)',
            }
          }}>
            Zaloguj&nbsp;się
          </Button>
        </Grid>

      </Grid>
    </Toolbar>
  </header>
}

export default Navbar;
