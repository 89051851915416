import React, {useCallback, useEffect, useRef, useState} from 'react';
import BaseCard from "../common/BaseCard";
import {Box, Grid, Typography} from "@mui/material";
import {StaticImage} from "gatsby-plugin-image";
import Link from "../common/Link";
import {withPrefix} from 'gatsby';


const data = [{
  image: <StaticImage src='../../images/vindicat.png' alt='Vindicat' imgStyle={{borderRadius: '50%'}}
                      placeholder='blurred'/>,
  text: 'Integracja z bramką płatniczą Fiberpay pozwoliła nam zautomatyzować całość procesu obsługi wierzytelności naszych klientów. ' +
    'Fiberpay obsługuje naliczanie prowizji oraz przekazuje wpłacone środki bezpośrednio na konto bankowe wierzyciela.',
  caption: 'Bogusław Bieda, prezes Vindicat',
  link: {
    text: "Sprawdź Vindicat", to: null,
  }
}, {
  image: <StaticImage src='../../images/domtel.png' alt='Domtel' imgStyle={{borderRadius: '50%'}}
                      placeholder='blurred'/>,
  text: 'Zintegrowaliśmy bramkę płatniczą Fiberpay z naszą aplikacją mobilną, aby ułatwić klientom opłacanie abonamentu za usługi telekomunikacyjne. ' +
    'Klienci mogą teraz uregulować należności bezpośrednio w aplikacji - przelewem online lub BLIK.',
  caption: 'Dariusz Dombek, prezes Domtel Telekom',
  link: {
    text: "Sprawdź Domtel Telekom", to: null,
  }
},
  {
    image: <StaticImage src='../../images/vindicat.png' alt='Vindicat' imgStyle={{borderRadius: '50%'}}
                        placeholder='blurred'/>,
    text: 'Integracja z bramką płatniczą Fiberpay pozwoliła nam zautomatyzować całość procesu obsługi wierzytelności naszych klientów. ' +
      'Fiberpay obsługuje naliczanie prowizji oraz przekazuje wpłacone środki bezpośrednio na konto bankowe wierzyciela.',
    caption: 'Bogusław Bieda, prezes Vindicat',
    link: {
      text: "Sprawdź Vindicat", to: null,
    }
  }, {
    image: <StaticImage src='../../images/domtel.png' alt='Domtel' imgStyle={{borderRadius: '50%'}}
                        placeholder='blurred'/>,
    text: 'Zintegrowaliśmy bramkę płatniczą Fiberpay z naszą aplikacją mobilną, aby ułatwić klientom opłacanie abonamentu za usługi telekomunikacyjne. ' +
      'Klienci mogą teraz uregulować należności bezpośrednio w aplikacji - przelewem online lub BLIK.',
    caption: 'Dariusz Dombek, prezes Domtel Telekom',
    link: {
      text: "Sprawdź Domtel Telekom", to: null,
    }
  },
]

const Quote = ({children}) => <Typography
  component='em'
  sx={{
    position: 'relative',
    fontWeight: 300,
    m: 0,
    '&::before, &::after': {
      content: '""',
      backgroundImage: `url(${withPrefix("/comma.png")}`,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100px',
      height: '100px',
      border: '2px solid red',
    }
  }}>
  {children}
</Typography>

const Testimonial = ({data, ...props}) => {
  const {image, text, caption, link} = data;

  return <BaseCard maxWidth='md' {...props}>
    <Grid container p={{xs: 1, md: 4}} component='figure'>
      <Grid item display={{xs: 'none', md: 'block'}} md={4} alignItems='center'>
        {image}
      </Grid>
      <Grid container item xs={12} md={8} spacing={2} component='blockquote'>
        <Grid item xs={12}><Quote>{text}</Quote></Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle2' sx={{fontWeight: 700, fontStyle: 'normal'}}
                      component='cite'>- {caption}</Typography>
        </Grid>
        <Grid item xs={12} textAlign='end' component='cite'
              sx={{fontStyle: 'normal'}}><Link>{link.text} >></Link></Grid>
      </Grid>
    </Grid>
  </BaseCard>
}

const onSideSx = {
  filter: 'blur(2px)',
  position: 'absolute',
  top: "35%",
  opacity: .8,
  zIndex: -1,
  display: {xs: 'none', xl: 'block'},
};

const getControlStyles = (isActive) => ({
  margin: '8px',
  borderRadius: '50%',
  width: '15px',
  height: '15px',
  border: '1px solid rgba(44, 62, 84, .1)',
  backgroundColor: isActive ? '#A6C9F2' : '#DEEBFA',
  cursor: 'pointer',
})

const HANDLER_INTERVAL = 10000;

const Testimonials = () => {
  const [currentIdx, setCurrentIdx] = useState(0);
  const handler = useRef(null);

  const getNextIdx = (idx) => (idx + 1) % data.length;
  const getPrevIdx = (idx) => (idx - 1 + data.length) % data.length;

  const handleNext = useCallback(() => setCurrentIdx(getNextIdx), []);

  useEffect(() => {
    handler.current = setInterval(handleNext, HANDLER_INTERVAL);
    return () => clearInterval(handler.current);
  }, [handleNext]);

  const handleSetIndex = (idx) => {
    clearInterval(handler.current);
    handler.current = setInterval(handleNext, HANDLER_INTERVAL);
    setCurrentIdx(idx);
  }

  return <div style={{overflowX: 'hidden'}}>
    <Box sx={{position: 'relative', overflow: 'visible', marginBottom: {xl: '10%'}}}>
      <Testimonial data={data[getPrevIdx(currentIdx)]} sx={{...onSideSx, left: '-10%',}}/>
      <Testimonial data={data[currentIdx]}/>
      <Testimonial data={data[getNextIdx(currentIdx)]} sx={{...onSideSx, right: '-10%'}}/>
    </Box>
    <div style={{display: "flex", justifyContent: 'center', marginBottom: '64px'}}>
      {data.map((d, idx) => (
        <div key={idx} style={getControlStyles(idx === currentIdx)} onClick={() => handleSetIndex(idx)}/>
      ))}
    </div>
  </div>
}

export default Testimonials;
