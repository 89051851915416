import LandingArticle from "./Article";
import {Grid, Typography} from "@mui/material";
import Button, {CallToActionButton} from "../common/Button";
import React from "react";
import LandingSection from "./LandingSection";
import BaseContainer from "../common/BaseContainer";
import BaseCard from '../common/BaseCard';
import FiberpayLogoLight from "../../images/fiberpay_logo_light.png";
import {ArrowForwardIosOutlined} from "@mui/icons-material";
import {Link} from "gatsby";

const Pricing = () => (
  <>
    <LandingSection
      id='cennik'
      subtitle='API na pierwszym miejscu'
      title='Automatyzuj przetwarzanie płatności Twoich użytkowników'
      colLeftText='Przyjmuj wpłaty, naliczaj prowizje od transakcji i przesyłaj płatności do odbiorców
        - zautomatyzowane za pomocą API i bez ręcznych rozliczeń, czy przelewów.'
      colRightText='Buduj rozwiązania typu marketplace umożliwiające rozliczenia pomiędzy użytkownikami,
      automatyzujące przyjmowanie należności lub ułatwiające masową dystrybucję środków do odbiorców.'
      // actionButtonText='Przykładowe projekty'
      // actionButtonUrl='/404' //TODO uzupełnić
    />
    <div>
      <BaseContainer sx={{my: 6}}>
        <BaseCard
          paperProps={{
            sx: {
              filter: 'drop-shadow(0px 9px 4px rgba(0, 0, 0, .35))',
              borderRadius: '20px',
              border: '1px solid #FFFFFF',
              borderBottom: '1px solid #F2F2F2',
              background: `url(${FiberpayLogoLight}) no-repeat center white`
            }
          }}
          cardFooter={
            <Typography variant='body2' textAlign='center' sx={{py: 1, color: 'neutral.dark', fontSize: '12px'}}>
              0% PROWIZJI PRZEZ 1 MIESIĄC - BEZ ZOBOWIĄZAŃ
            </Typography>
          }>
          <Grid container item textAlign='center' xs={12} spacing={1} sx={{mb: 4, mt: 2}}>
            <Grid item xs={12}>
              <Typography sx={{fontWeight: 700}}>
                Koszt obsługi płatności Twoich klientów to tylko
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h3' sx={{
                fontWeight: 700,
                position: 'relative',
                '&::after': {
                  content: '"(min. 2 PLN)"',
                  position: 'absolute',
                  fontSize: '10px',
                  fontWeight: '400',
                  color: 'neutral.dark',
                  bottom: '12px',
                }
              }}>
                2.0%
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{fontWeight: 700}}>
                Bez stałych opłat. Bez ukrytych kosztów.
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{display: 'none'}}>
              <Button sx={{mt: 3, mb: 2}} variant='contained' endIcon={<ArrowForwardIosOutlined/>}>Wypróbuj</Button>
            </Grid>
          </Grid>
        </BaseCard>
      </BaseContainer>
    </div>
    <LandingArticle>
      <Grid container sx={{py: 8, color: 'white'}}>
        <Grid item xs={12}>
          <Typography variant='h4' sx={{fontWeight: 'bold'}}>
            Zacznij już teraz
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'flex-end', pt: {xs: 6, md: 0}}}>
          <Typography>
            Zarejestruj się, aby przyjmować płatności w Twojej
            aplikacji<br/>- <strong>szybko</strong>, <strong>bezpiecznie</strong> i zgodnie <strong>z prawem</strong>!
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent: 'flex-end', pt: {xs: 6, md: 0}}}>
          <Link to='/register'>
            <CallToActionButton>Zarejestruj&nbsp;się</CallToActionButton>
          </Link>
        </Grid>
      </Grid>
    </LandingArticle>
  </>
)

export default Pricing;
