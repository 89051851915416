import React from 'react';
import {styled} from "@mui/system";
import {lighten} from "@mui/material/styles";
import BaseContainer from "../common/BaseContainer";

const StyledArticle = styled('article')(({theme, ...props}) => ({
  background: `linear-gradient(
    90deg,
    ${theme.palette.primary.darker} 0%,
    ${lighten(theme.palette.primary.lighter, .2)} 100%)`,
}));

const LandingArticle = ({children, ...props}) => (
  <StyledArticle {...props}>
    <BaseContainer component='div'>
      {children}
    </BaseContainer>
  </StyledArticle>
)

export default LandingArticle;
