import {Grid, Typography} from "@mui/material";
import Button from "../common/Button";
import React from "react";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import PhonelinkLockOutlinedIcon from "@mui/icons-material/PhonelinkLockOutlined";
import LandingSection from "./LandingSection";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FiberpayWhite from "../../images/fiberpay-white.png";
import {lighten} from "@mui/material/styles";


const products = [{
  name: 'FiberPay',
  description: <>Przyjmuj płatności za pomocą szybkich przelewów (pay-by-link),<br/> BLIK i kart płatniczych. Wysyłaj
    płatności masowe.</>,
  icon: <PaymentOutlinedIcon sx={{fontSize: 60}}/>,
}, {
  name: 'FiberKYC',
  description: <>Weryfikuj tożsamość klientów, których obsługujesz,<br/>zapobiegaj anonimowości użytkowników.</>,
  icon: <BadgeOutlinedIcon sx={{fontSize: 60}}/>,
}, {
  name: 'FiberAML',
  description: <>Zautomatyzuj ocenę ryzyka klientów i transakcji,<br/>obniż koszty zgodności w wymogami
    regulacyjnymi.</>,
  icon: <PersonSearchOutlinedIcon sx={{fontSize: 60}}/>,
}, {
  name: 'FiberToken',
  description: <>Zwiększ bezpieczeństwo swoich użytkowników<br/>dzięki autoryzacji operacji w dedykowanej aplikacji
    mobilnej.</>,
  icon: <PhonelinkLockOutlinedIcon sx={{fontSize: 60}}/>,
},];

const Products = () => (
  <LandingSection
    sx={{
      background: (theme) => `url(${FiberpayWhite}) no-repeat center, linear-gradient(
    90deg,
    ${lighten(theme.palette.primary.lighter, .2)} 0%,
    ${theme.palette.primary.darker} 100%)`,
      marginLeft: 0,
      marginRight: 0,
    }}
    id='produkty'
    subtitle='Wszystko w jednym miejscu'
    title='Nie trać czasu na integrację rozwiązań od wielu dostawców'
    colLeftText='Sprawnie zbuduj Twoje procesy onboardingu klientów, weryfikacji tożsamości, oceny
          ryzyka, autoryzacji transakcji, wielokanałowego przyjmowania płatności, naliczania prowizji od transakcji oraz
          dystrybucji wypłat - wszystko na jednej, łatwej w obsłudze platformie technologicznej.'
    colRightText='Obniż koszty budowy i utrzymania aplikacji oraz zapewnij bezpieczeństwo i zgodność z
          obowiązującymi regulacjami - bez rozwiązywania problemów ze współpracą rozwiązań od wielu różnych
          dostawców.'
    actionButtonText='Zacznij od płatności'
    actionButtonUrl={'null'}
  >
    <Grid container mt={{xs: 4}} spacing={2}>
      {products.map(({name, icon, description}) => (
        <Grid key={name} container item xs={12} md={6} lg={3} sx={{
          mt: {xs: 2, lg: 0}
        }}>
          <Grid container item sx={{
            borderRadius: '20px',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            p: 4,
            '&:hover': {
              boxShadow: '-2px -2px 4px rgba(255, 255, 255, 0.22), 4px 4px 8px rgba(0, 0, 0, 0.62)'
            }
          }}>
            <Grid item xs={12} textAlign='center'>{icon}</Grid>
            <Grid item xs={12} textAlign='center'>
              <Typography sx={{fontWeight: 'bold'}}>{name}</Typography>
            </Grid>
            <Grid item xs={12} textAlign='center'>{description}</Grid>
            <Grid item xs={12} textAlign='center' sx={{mt: 8, display: 'none'}}>
              <Button
                endIcon={<ArrowForwardIosIcon fontSize='16px'/>}
              >Więcej</Button>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  </LandingSection>
)

export default Products;
