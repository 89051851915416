import React from "react"
import Hero from "../components/landing/Hero";
import SubsidyInfoBanner from "../components/landing/SubsidyInfoBanner";
import Footer from "../components/common/Footer";
import ForDevelopers from "../components/landing/ForDevelopers";
import Products from "../components/landing/Products";
import Pricing from "../components/landing/Pricing";
import EuSubsidyInfo from "../components/landing/EuSubsidyInfo";
import Compliance from "../components/landing/Compliance";
import Support from "../components/landing/Support";
import Seo from "../seo";

const IndexPage = () => {
  return (
    <main>
      <Seo  />
      <SubsidyInfoBanner />
      <Hero/>
      <ForDevelopers />
      <Products />
      <Support />
      <Compliance />
      <Pricing />
      <EuSubsidyInfo />
      <Footer />
    </main>
  )
}

export default IndexPage
