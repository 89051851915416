import React from 'react';
import LandingArticle from "./Article";
import {Grid, Typography} from "@mui/material";
import Button from "../common/Button";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import {Link} from "gatsby";

const ActionButton = ({url, text, isExternal}) => {
  const sx = {textDecoration: 'none'}
  if (isExternal) {
    return <Button component='a'
                   href={url}
                   target="_blank"
                   rel='noopener noreferrer' sx={sx} variant='contained'
                   endIcon={<ArrowForwardOutlinedIcon/>}>
      {text}
    </Button>
  }

  return <Link to={url} style={{textDecoration: 'none'}}>
    <Button sx={sx} variant='contained'
            endIcon={<ArrowForwardOutlinedIcon/>}>
      {text}
    </Button>
  </Link>

}

const LandingSection = ({
                          children,
                          inverted,
                          title,
                          subtitle,
                          colLeftText,
                          colRightText,
                          actionButtonText,
                          actionButtonUrl,
                          isActionUrlExternal,
                          ...props
                        }) => {
  const articleSx = {py: 9};
  if (inverted) {
    articleSx.background = 'transparent';
  } else {
    articleSx.color = 'white';
  }

  return (<LandingArticle {...props} sx={{...articleSx, ...(props.sx || {})}}>
    <Typography component='h4' sx={{pb: 1, fontWeight: 'bold', color: inverted ? 'primary.light' : 'white'}}>
      {subtitle}
    </Typography>
    <Typography variant='h4' component='h3' sx={{fontSize: '36px', fontWeight: 'bold'}}>
      {title}
    </Typography>
    <Grid container sx={{my: 0}} spacing={4}>
      <Grid item xs={12} md={6}>{colLeftText}</Grid>
      <Grid item xs={12} md={6}>{colRightText}</Grid>
      {actionButtonUrl && (
        <Grid item xs={12} sx={{mt: 2}}>
          <ActionButton url={actionButtonUrl} text={actionButtonText} isExternal={isActionUrlExternal}/>
        </Grid>
      )}
    </Grid>
    {children}
  </LandingArticle>)
};

export default LandingSection;
