import {Grid} from "@mui/material";
import {StaticImage} from "gatsby-plugin-image";
import React from "react";
import LandingSection from "./LandingSection";
import {lighten} from "@mui/material/styles";
import KnfBackground from '../../images/knf_background.png';

const Compliance = () => (
  <LandingSection
    inverted
    sx={{
      position: 'relative',
      overflow: 'hidden',
      pt: (theme => theme.spacing(24)),
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: (theme) => theme.spacing(12),
        zIndex: -1,
        background: (theme => `linear-gradient(
    90deg,
    ${theme.palette.primary.darker} 0%,
    ${lighten(theme.palette.primary.lighter, .2)} 100%)`),
        // clipPath: 'polygon(0 0, 75% 0%, 100% 0, 100% 200px, 75% 19%, 0 19%)',
      },
    }}
    id='branze'
    title='Twój biznes jest w dobrych rękach'
    subtitle='Bezpieczeństwo i zgodność z regulacjami'
    colLeftText='Pomożemy Ci w szczegółowej analizie ryzyk regulacyjnych związanych z Twoim modelem rozliczeń
      i zaproponujemy optymalny, zgodny z prawem model korzystania z usług płatniczych.'
    colRightText='Twój biznes jest z nami bezpieczny - spełniamy wymogi prawno-regulacyjne,
      współpracujemy z doświadczonymi partnerami oraz zabezpieczamy naszą technologię przy pomocy sprawdzonych technik i narzędzi.'
    // actionButtonText='Przykładowe projekty'
    // actionButtonUrl='/404' //TODO uzupełnić
  >
    <Grid container sx={{mt: 2, py: 8, position: 'relative', background: `url(${KnfBackground}) no-repeat center`}} spacing={4}>
      <Grid item xs={12} md={6} textAlign={{xs: 'center', md: 'end'}}>
        <StaticImage src={'../../images/knf_logo.png'} alt='KNF' placeholder='blurred'/>
      </Grid>
      <Grid container item xs={12} md={6}
            sx={{display: 'flex', justifyContent: {xs: 'center', md: 'flex-start'}, color: 'primary.light'}}>
        <b>Nasze płatności są nadzorowane przez KNF</b>
        Wpis do rejestru małych instytucji płatniczych: MIP28/2019
      </Grid>
    </Grid>
  </LandingSection>
)

export default Compliance;
