import React from 'react';
import {Typography} from "@mui/material";
import {StaticImage} from "gatsby-plugin-image";
import BaseContainer from "../common/BaseContainer";

const SubsidyInfoBanner = () => (
  <div style={{backgroundColor: 'white'}}>
    <BaseContainer sx={{
      display: 'flex',
      alignItems: {xs: 'flex-start', md: 'center'},
      justifyContent: 'space-between',
      flexDirection: {xs: 'column', md: 'row'},
    }}>
      <Typography sx={{color: 'primary.light', p: 1, fontSize: '10px'}}>
        FiberPay sp. z o.o. realizuje projekt dofinansowany z funduszy Europejskich
        “Automatyzacja procesu zgłaszania informacji do GIIF”
      </Typography>
      <StaticImage src='../../images/eu_logotype.jpg' alt='Unia Europejska' objectFit={'contain'} width={142}/>

    </BaseContainer>
  </div>
);

export default SubsidyInfoBanner;
